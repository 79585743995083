<template>
  <div class="outer-container">
    <div class="ccer-contract">
      <h1>碳汇合作开发协议</h1>

      <div class="contract-content">
        <p>
        <strong>第一条 项目概述</strong>
      </p>
        
        <p>1.1 项目名称：_____碳汇开发项目</p>
        <p>1.2 项目地点：_____</p>
        <p>1.3 项目规模：_____</p>
      

        <p>
        <strong>第二条 双方权利和义务</strong>
      </p>
      <p>
        <strong>2.1 甲方权利和义务：</strong>
      </p>
       
        <p>(1) 负责项目的整体规划、设计、建设和运营；</p>
        <p>(2) 确保项目的合法性和合规性，取得必要的许可和证书；</p>
        <p>(3) 负责项目的市场推广和销售工作；</p>
        <p>(4) 按照约定向乙方分配收益。</p>

        <p>
        <strong>2.2 乙方权利和义务：</strong>
      </p>
        
        <p>(1) 按照约定投入资金，参与项目的开发建设；</p>
        <p>(2) 监督项目的进展情况和资金使用情况；</p>
        <p>(3) 享有项目收益的权利；</p>
        <p>(4) 协助甲方进行市场推广和销售工作。</p>
      

     
        <p>
        <strong>第三条 投资及收益分配</strong>
      </p>
       
        <p>3.1 乙方投资金额：人民币_____元。</p>
        <p>3.2 收益分配方式：按照双方约定的比例分配项目收益。具体分配比例根据项目的实际情况和双方的协商确定。</p>
     

        <p>
        <strong>第四条 风险与责任</strong>
      </p>
        <h2></h2>
        <p>双方应共同承担项目开发过程中的风险，包括但不限于技术风险、市场风险、政策风险等。同时，双方应确保项目的合法性和合规性，并充分评估项目风险，以保障各自的权益。</p>
     

        <p>
        <strong>第五条 合同变更和解除</strong>
      </p>
        
        <p>5.1 本合同的变更和解除应经双方协商一致，并以书面形式确认。</p>
        <p>5.2 在合同期限内，如一方有违约行为或发生不可抗力事件导致合同无法履行，另一方有权解除本合同。</p>
      

        <p>
        <strong>第六条 争议解决</strong>
      </p>

        <p>6.1 因本合同的解释、履行或争议解决产生的任何争议，双方应首先尝试友好协商解决。协商不成的，任何一方均有权向_____（合同签订地/被告住所地）人民法院提起诉讼。</p>
      

        <p>
        <strong>第七条 附则</strong>
      </p>
       
        <p>7.1 本合同自双方签字（或盖章）之日起生效，有效期为_____年。</p>
        <p>7.2 本合同一式两份，甲乙双方各执一份。本合同未尽事宜，可由双方协商补充。经双方协商一致达成的补充协议，与本合同具有同等法律效力。</p>
        <p>
          甲方（盖章）：
          <br />法定代表人（签字）：
          <br />签订日期：__年__月__日
        </p>
        <p>
          乙方（盖章）：
          <br />法定代表人（签字）：
          <br />签订日期：__年__月__日
        </p>
        <p>此模板仅供参考，具体内容、条款及百分比等数据，需要根据实际情况确定。在签署正式合同前，建议咨询法律专业人士，确保合同的有效性和可执行性。</p>
    </div>
    <div class="download-section">
        <a href="URL_TO_YOUR_WORD_FILE" download="CCER交易买卖合同.docx" class="download-button">下载合同模板</a>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {};
</script>

<style>
.outer-container {
  background-color: rgb(225, 225, 225); /* 设置为红色背景 */
  padding: 130px; /* 控制内部div与红色背景的距离 */
}
.ccer-contract {
  background-color: white; /* 显式设置为白色，但通常默认就是白色 */
  /* 其他样式，如宽度、高度等 */
  width: 100%;
  height: 100%; /* 如果需要的话 */
  box-sizing: border-box; /* 确保padding不会影响宽度 */
}
h1,
h2 {
  text-align: center;
}
p {
  margin-bottom: 1em;
}

.contract-content {
  margin: 0 auto;
  width: 80%;
}
.contract-content p {
  text-indent: 2em; /* 首行缩进 */
}
.download-section {
  text-align: center; /* 让下载按钮居中显示 */
  margin-top: 20px; /* 在下载部分和其它内容之间增加一些间距 */
}

.download-button {
  display: inline-block;
  background-color: #007bff; /* 蓝色背景 */
  color: white; /* 白色文字 */
  padding: 10px 20px; /* 内边距 */
  text-decoration: none; /* 去除下划线 */
  border-radius: 5px; /* 轻微的圆角 */
  font-size: 16px; /* 文字大小 */
  cursor: pointer; /* 鼠标悬停时显示手形图标 */
  transition: background-color 0.3s; /* 为背景色变化添加过渡效果 */
}

.download-button:hover {
  background-color: #0056b3; /* 鼠标悬停时深蓝色背景 */
}
</style>