<template>
  <div class="news">
    <header id="myHeader"></header>
    <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%; font-weight: 400;">
    <el-tab-pane label="政策资讯" name="first">
       <NewZheng></NewZheng>
    </el-tab-pane>
    <el-tab-pane label="行业新闻" name="second">
     <HangYe></HangYe>
    </el-tab-pane>
  
  </el-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import NewZheng from '@/components/New_zhengce.vue'
import HangYe from '@/components/New_hangye.vue'

export default {
  name: "Home",
  components: {
    HelloWorld,
    NewZheng,
    HangYe
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    handleClick(tab, event) {
        console.log(tab, event);
      
      },
  },
  data() {
      return {
        activeName: 'first'
      };
    },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}


#myHeader {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 390px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen-shouye.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
.el-tabs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #000;
    height: 45px;
    background-color: rgb(234, 234, 234);
    border-radius: 25px;
  }
  ::v-deep .el-tabs__header{
    margin: 0;
  }
  /* 设置滑块颜色 */
	::v-deep .el-tabs__active-bar{
	  /* background-color: #538FFF !important; */
    background: linear-gradient(135deg, #548fec, #c2e9fb);
	}
  /* 设置滑块停止位置 */
	::v-deep .el-tabs__active-bar.is-top{
	  height: 50px;
	  width: 154px ! important;
	  top: 0px !important;
	  left: -50px !important;
	  position: absolute !important;
	  z-index: 1;
    text-align: center;
	}
  ::v-deep .el-tabs__nav-wrap::after {
	  position: static !important;
	}
  /* 设置当前选中样式 */
	::v-deep .el-tabs__item.is-active{
	  color:#ffffff !important;
    width: 180px;
	  z-index: 2;
	}
  /* 设置未被选中样式 */
	::v-deep .el-tabs__item{
	  padding: 0 50px !important;
	  width: 180px;
	  box-sizing: border-box;
	  display: inline-block;
	  position: relative !important;
	  color:#000000 !important;
	  z-index: 2;
	}
.but {
  margin-top: 10px;
  padding: 10px 20px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff; /* 文本颜色，可根据需要调整 */
  border: none;
  border-radius: 4px; /* 圆角大小，可根据需要调整 */
  background-image: linear-gradient(
    to right,
    #8bc1fc,
    #298bf3
  ); /* 渐变效果，从左到右，颜色从浅蓝到深蓝 */
  cursor: pointer; /* 鼠标悬停时变为手型 */
  transition: background 0.3s ease; /* 背景渐变过渡效果 */
  margin-bottom: 50px;
}
.but:hover {
  background-image: linear-gradient(
    to right,
    #0056b3,
    #007bff
  ); /* 鼠标悬停时的渐变效果 */
}

.but:active {
  background-image: linear-gradient(
    to right,
    #003a7f,
    #004a8c
  ); /* 点击时的渐变效果 */
}

.statistics {
  display: flex;
  justify-content: space-around;
  margin-top: -80px;
}

.statistic {
  text-align: center;
}


</style>
  
 