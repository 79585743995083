<template>  
  <div class="notification-page">  
    <header>  
      <h1>财政部关于印发《碳排放权交易有关会计处理暂行规定》的通知</h1>  
      <p class="doc-number">(财会〔2019〕22号)</p>  
      <p class="date">发布日期：2019年12月16日</p>  
    </header>  
  
    <main>  

      <h2>通知内容</h2>  
      <p>为配合我国碳排放权交易的开展，规范碳排放权交易相关的会计处理，根据《中华人民共和国会计法》和企业会计准则等相关规定，我们制定了《碳排放权交易有关会计处理暂行规定》，现予印发。</p>  
  
      <h3>附件：碳排放权交易有关会计处理暂行规定</h3>  
      <section>
        <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new11.jpg" width="800px" height="300px">
      </section>
      <section>  
        <h4>一、适用范围</h4>  
        <p>本规定适用于按照《碳排放权交易管理暂行办法》等有关规定开展碳排放权交易业务的重点排放单位中的相关企业(以下简称重点排放企业)。</p>  
        <!-- 其他章节内容继续添加 -->  
      </section>  
  
      <section>  
        <h3>二、会计处理原则</h3>  
        <p>重点排放企业通过购入方式取得碳排放配额的，应当在购买日将取得的碳排放配额确认为碳排放权资产，并按照成本进行计量。</p>  
        <p>重点排放企业通过政府免费分配等方式无偿取得碳排放配额的，不作账务处理。</p>  
      </section>  
  
      <section>  
        <h3>三、会计科目设置</h3>  
        <p>重点排放企业应当设置“1489碳排放权资产”科目，核算通过购入方式取得的碳排放配额。</p>  
      </section>  
  
      <section>  
        <h3>四、账务处理</h3>  
        <h4>(一)购入碳排放配额</h4>  
        <p>重点排放企业购入碳排放配额的，按照购买日实际支付或应付的价款(包括交易手续费等相关税费)，借记“碳排放权资产”科目，贷记“银行存款”　“其他应付款”等科目。</p>  
        <!-- ... 其他账务处理细节 ... -->  
  
        <h4>(二)使用碳排放配额履约</h4>  
        <p>重点排放企业使用购入的碳排放配额履约的，按照所使用配额的账面余额，借记“营业外支出”科目，贷记“碳排放权资产”科目。</p>  
        <!-- ... 其他使用配额的账务处理细节 ... -->  
  
        <!-- ... 继续添加其他账务处理细节，如出售、注销等 ... -->  
      </section>  
  
      <section>  
        <h3>五、财务报表列示和披露</h3>  
        <p>“碳排放权资产”科目的借方余额在资产负债表中的“其他流动资产”项目列示。</p>  
        <p>重点排放企业应当在财务报表附注中披露与碳排放权交易相关的信息，包括参与减排机制的特征、碳排放战略、节能减排措施等。</p>  
        <!-- ... 其他披露要求的细节 ... -->  
  
        <h4>碳排放配额变动情况表</h4>  
        <table>  
          <thead>  
            <tr>  
              <th rowspan="2">项　目</th>  
              <th colspan="2">本年度</th>  
              <th colspan="2">上年度</th>  
            </tr>  
            <tr>  
              <th>数量（吨）</th>  
              <th>金额（元）</th>  
              <th>数量（吨）</th>  
              <th>金额（元）</th>  
            </tr>  
          </thead>  
          <tbody>  
            <!-- 这里可以动态生成表格行数据 -->  
          </tbody>  
        </table>  
      </section>   
  
      <section>  
        <h4>六、附则</h4>  
        <p>重点排放企业的国家核证自愿减排量相关交易，参照本规定进行会计处理，在“碳排放权资产”科目下设置明细科目进行核算。</p>  
        <p>本规定自2020年1月1日起施行，重点排放企业应当采用未来适用法应用本规定。</p>  
      </section>  
  
      <h3>财务报表列示和披露示例</h3>  
     
    </main>  
  
    <footer>  
      <p>&copy; 财政部 2019</p>  
    </footer>  
  </div>  
</template>  
  
<script>  
export default {  
  // ... 组件的其他选项和数据 ...  
};  
</script>  
  
<style scoped>  
.notification-page {  
  max-width: 800px;  
  margin: 0 auto;  
  padding: 20px;  
  font-family: Arial, sans-serif;  
}  
  
header {  
  text-align: center;  
}  
  
main {  
  margin-top: 20px;  
}  
  
table {  
  width: 100%;  
  border-collapse: collapse;  
}  
  
th, td {  
  border: 1px solid #ddd;  
  padding: 8px;  
  text-align: left;  
}  
  
th {  
  background-color: #f2f2f2;  
}  
  
/* 其他样式可以根据需要添加 */  
</style>