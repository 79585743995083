<template>
  <div class="notification-page">
    <header>
      <h1>中华人民共和国生态环境部令</h1>
      <p class="doc-number">
        (《碳排放权交易管理办法（试行）》已于2020年12月25日由生态环境部部务会议审议通过，现予公布，自2021年2月1日起施行。
        )
      </p>
      <p class="date">部长 黄润秋</p>
      <p class="date">2020年12月31日</p>
    </header>

    <main>
      <h2>碳排放权交易管理办法（试行）</h2>
      <section>
        <img
          src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new11.jpg"
          width="800px"
          height="300px"
        />
      </section>
      <section>
        <h4>第一章 总 则</h4>
        <p>第一条 为落实党中央、国务院关于建设全国碳排放权交易市场的决策部署，在应对气候变化和促进绿色低碳发展中充分发挥市场机制作用，推动温室气体减排，规范全国碳排放权交易及相关活动，根据国家有关温室气体排放控制的要求，制定本办法。</p>
        <p>第二条 本办法适用于全国碳排放权交易及相关活动，包括碳排放配额分配和清缴，碳排放权登记、交易、结算，温室气体排放报告与核查等活动，以及对前述活动的监督管理。</p>
        <p>第三条 全国碳排放权交易及相关活动应当坚持市场导向、循序渐进、公平公开和诚实守信的原则。</p>
        <p>第四条 生态环境部按照国家有关规定建设全国碳排放权交易市场。</p>
        <p>全国碳排放权交易市场覆盖的温室气体种类和行业范围，由生态环境部拟订，按程序报批后实施，并向社会公开。</p>
        <p>第五条 生态环境部按照国家有关规定，组织建立全国碳排放权注册登记机构和全国碳排放权交易机构，组织建设全国碳排放权注册登记系统和全国碳排放权交易系统。</p>
        <p>全国碳排放权注册登记机构通过全国碳排放权注册登记系统，记录碳排放配额的持有、变更、清缴、注销等信息，并提供结算服务。全国碳排放权注册登记系统记录的信息是判断碳排放配额归属的最终依据。</p>
        <p>全国碳排放权交易机构负责组织开展全国碳排放权集中统一交易。</p>
        <p>全国碳排放权注册登记机构和全国碳排放权交易机构应当定期向生态环境部报告全国碳排放权登记、交易、结算等活动和机构运行有关情况，以及应当报告的其他重大事项，并保证全国碳排放权注册登记系统和全国碳排放权交易系统安全稳定可靠运行。</p>
        <p>第六条 生态环境部负责制定全国碳排放权交易及相关活动的技术规范，加强对地方碳排放配额分配、温室气体排放报告与核查的监督管理，并会同国务院其他有关部门对全国碳排放权交易及相关活动进行监督管理和指导。</p>
        <p>省级生态环境主管部门负责在本行政区域内组织开展碳排放配额分配和清缴、温室气体排放报告的核查等相关活动，并进行监督管理。</p>
        <p>设区的市级生态环境主管部门负责配合省级生态环境主管部门落实相关具体工作，并根据本办法有关规定实施监督管理。</p>
        <p>第七条 全国碳排放权注册登记机构和全国碳排放权交易机构及其工作人员，应当遵守全国碳排放权交易及相关活动的技术规范，并遵守国家其他有关主管部门关于交易监管的规定。</p>
        <!-- 其他章节内容继续添加 -->
      </section>

      <section>
        <h3>第二章 温室气体重点排放单位</h3>
        <p>第八条 温室气体排放单位符合下列条件的，应当列入温室气体重点排放单位（以下简称重点排放单位）名录：</p>
        <p>（一）属于全国碳排放权交易市场覆盖行业；</p>
        <p>（二）年度温室气体排放量达到2.6万吨二氧化碳当量。</p>

        <p>第九条 省级生态环境主管部门应当按照生态环境部的有关规定，确定本行政区域重点排放单位名录，向生态环境部报告，并向社会公开。</p>

        <p>第十条 重点排放单位应当控制温室气体排放，报告碳排放数据，清缴碳排放配额，公开交易及相关活动信息，并接受生态环境主管部门的监督管理。</p>

        <p>第十一条 存在下列情形之一的，确定名录的省级生态环境主管部门应当将相关温室气体排放单位从重点排放单位名录中移出：</p>
        <p>（一）连续二年温室气体排放未达到2.6万吨二氧化碳当量的；</p>
        <p>（二）因停业、关闭或者其他原因不再从事生产经营活动，因而不再排放温室气体的。</p>

        <p>第十二条 温室气体排放单位申请纳入重点排放单位名录的，确定名录的省级生态环境主管部门应当进行核实；经核实符合本办法第八条规定条件的，应当将其纳入重点排放单位名录。</p>

        <p>第十三条 纳入全国碳排放权交易市场的重点排放单位，不再参与地方碳排放权交易试点市场。</p>
      </section>

      <section>
        <h3>第三章 分配与登记</h3>

        <p>第十四条 生态环境部根据国家温室气体排放控制要求，综合考虑经济增长、产业结构调整、能源结构优化、大气污染物排放协同控制等因素，制定碳排放配额总量确定与分配方案。</p>
        <p>省级生态环境主管部门应当根据生态环境部制定的碳排放配额总量确定与分配方案，向本行政区域内的重点排放单位分配规定年度的碳排放配额。</p>

        <p>第十五条 碳排放配额分配以免费分配为主，可以根据国家有关要求适时引入有偿分配。</p>

        <p>第十六条 省级生态环境主管部门确定碳排放配额后，应当书面通知重点排放单位。</p>
        <p>重点排放单位对分配的碳排放配额有异议的，可以自接到通知之日起七个工作日内，向分配配额的省级生态环境主管部门申请复核；省级生态环境主管部门应当自接到复核申请之日起十个工作日内，作出复核决定。</p>

        <p>第十七条 重点排放单位应当在全国碳排放权注册登记系统开立账户，进行相关业务操作。</p>

        <p>第十八条 重点排放单位发生合并、分立等情形需要变更单位名称、碳排放配额等事项的，应当报经所在地省级生态环境主管部门审核后，向全国碳排放权注册登记机构申请变更登记。全国碳排放权注册登记机构应当通过全国碳排放权注册登记系统进行变更登记，并向社会公开。</p>

        <p>第十九条 国家鼓励重点排放单位、机构和个人，出于减少温室气体排放等公益目的自愿注销其所持有的碳排放配额。</p>
        <p>自愿注销的碳排放配额，在国家碳排放配额总量中予以等量核减，不再进行分配、登记或者交易。相关注销情况应当向社会公开。</p>
      </section>

      <section>
        <h3>第四章 排放交易</h3>
        <p>第二十条 全国碳排放权交易市场的交易产品为碳排放配额，生态环境部可以根据国家有关规定适时增加其他交易产品。</p>
        <p>第二十一条 重点排放单位以及符合国家有关交易规则的机构和个人，是全国碳排放权交易市场的交易主体。</p>
        <p>第二十二条 碳排放权交易应当通过全国碳排放权交易系统进行，可以采取协议转让、单向竞价或者其他符合规定的方式。</p>
        <p>全国碳排放权交易机构应当按照生态环境部有关规定，采取有效措施，发挥全国碳排放权交易市场引导温室气体减排的作用，防止过度投机的交易行为，维护市场健康发展。</p>
        <p>第二十三条 全国碳排放权注册登记机构应当根据全国碳排放权交易机构提供的成交结果，通过全国碳排放权注册登记系统为交易主体及时更新相关信息。</p>
        <p>第二十四条 全国碳排放权注册登记机构和全国碳排放权交易机构应当按照国家有关规定，实现数据及时、准确、安全交换。</p>
      </section>

      <section>
        <h3>第五章 排放核查与配额清缴</h3>
        <p>第二十五条 重点排放单位应当根据生态环境部制定的温室气体排放核算与报告技术规范，编制该单位上一年度的温室气体排放报告，载明排放量，并于每年3月31日前报生产经营场所所在地的省级生态环境主管部门。排放报告所涉数据的原始记录和管理台账应当至少保存五年。</p>
        <p>重点排放单位对温室气体排放报告的真实性、完整性、准确性负责。</p>
        <p>重点排放单位编制的年度温室气体排放报告应当定期公开，接受社会监督，涉及国家秘密和商业秘密的除外。</p>

        <p>第二十六条 省级生态环境主管部门应当组织开展对重点排放单位温室气体排放报告的核查，并将核查结果告知重点排放单位。核查结果应当作为重点排放单位碳排放配额清缴依据。</p>
        <p>省级生态环境主管部门可以通过政府购买服务的方式委托技术服务机构提供核查服务。技术服务机构应当对提交的核查结果的真实性、完整性和准确性负责。</p>

        <p>第二十七条 重点排放单位对核查结果有异议的，可以自被告知核查结果之日起七个工作日内，向组织核查的省级生态环境主管部门申请复核；省级生态环境主管部门应当自接到复核申请之日起十个工作日内，作出复核决定。</p>

        <p>第二十八条 重点排放单位应当在生态环境部规定的时限内，向分配配额的省级生态环境主管部门清缴上年度的碳排放配额。清缴量应当大于等于省级生态环境主管部门核查结果确认的该单位上年度温室气体实际排放量。</p>

        <p>第二十九条 重点排放单位每年可以使用国家核证自愿减排量抵销碳排放配额的清缴，抵销比例不得超过应清缴碳排放配额的5%。相关规定由生态环境部另行制定。</p>
        <p>用于抵销的国家核证自愿减排量，不得来自纳入全国碳排放权交易市场配额管理的减排项目。</p>
      </section>

      <section>
        <h3>第六章 监督管理</h3>
        <p>第三十条 上级生态环境主管部门应当加强对下级生态环境主管部门的重点排放单位名录确定、全国碳排放权交易及相关活动情况的监督检查和指导。</p>

        <p>第三十一条 设区的市级以上地方生态环境主管部门根据对重点排放单位温室气体排放报告的核查结果，确定监督检查重点和频次。</p>
        <p>设区的市级以上地方生态环境主管部门应当采取“双随机、一公开”的方式，监督检查重点排放单位温室气体排放和碳排放配额清缴情况，相关情况按程序报生态环境部。</p>

        <p>第三十二条 生态环境部和省级生态环境主管部门，应当按照职责分工，定期公开重点排放单位年度碳排放配额清缴情况等信息。</p>

        <p>第三十三条 全国碳排放权注册登记机构和全国碳排放权交易机构应当遵守国家交易监管等相关规定，建立风险管理机制和信息披露制度，制定风险管理预案，及时公布碳排放权登记、交易、结算等信息。</p>
        <p>全国碳排放权注册登记机构和全国碳排放权交易机构的工作人员不得利用职务便利谋取不正当利益，不得泄露商业秘密。</p>

        <p>第三十四条 交易主体违反本办法关于碳排放权注册登记、结算或者交易相关规定的，全国碳排放权注册登记机构和全国碳排放权交易机构可以按照国家有关规定，对其采取限制交易措施。</p>

        <p>第三十五条 鼓励公众、新闻媒体等对重点排放单位和其他交易主体的碳排放权交易及相关活动进行监督。</p>
        <p>重点排放单位和其他交易主体应当按照生态环境部有关规定，及时公开有关全国碳排放权交易及相关活动信息，自觉接受公众监督。</p>

        <p>第三十六条 公民、法人和其他组织发现重点排放单位和其他交易主体有违反本办法规定行为的，有权向设区的市级以上地方生态环境主管部门举报。</p>
        <p>接受举报的生态环境主管部门应当依法予以处理，并按照有关规定反馈处理结果，同时为举报人保密。</p>
      </section>

      <section>
        <h3>第七章 罚 则</h3>
        <p>第三十七条 生态环境部、省级生态环境主管部门、设区的市级生态环境主管部门的有关工作人员，在全国碳排放权交易及相关活动的监督管理中滥用职权、玩忽职守、徇私舞弊的，由其上级行政机关或者监察机关责令改正，并依法给予处分。</p>

        <p>第三十八条 全国碳排放权注册登记机构和全国碳排放权交易机构及其工作人员违反本办法规定，有下列行为之一的，由生态环境部依法给予处分，并向社会公开处理结果：</p>
        <p>（一）利用职务便利谋取不正当利益的；</p>
        <p>（二）有其他滥用职权、玩忽职守、徇私舞弊行为的。</p>
        <p>全国碳排放权注册登记机构和全国碳排放权交易机构及其工作人员违反本办法规定，泄露有关商业秘密或者有构成其他违反国家交易监管规定行为的，依照其他有关规定处理。</p>

        <p>第三十九条 重点排放单位虚报、瞒报温室气体排放报告，或者拒绝履行温室气体排放报告义务的，由其生产经营场所所在地设区的市级以上地方生态环境主管部门责令限期改正，处一万元以上三万元以下的罚款。逾期未改正的，由重点排放单位生产经营场所所在地的省级生态环境主管部门测算其温室气体实际排放量，并将该排放量作为碳排放配额清缴的依据；对虚报、瞒报部分，等量核减其下一年度碳排放配额。</p>

        <p>第四十条 重点排放单位未按时足额清缴碳排放配额的，由其生产经营场所所在地设区的市级以上地方生态环境主管部门责令限期改正，处二万元以上三万元以下的罚款；逾期未改正的，对欠缴部分，由重点排放单位生产经营场所所在地的省级生态环境主管部门等量核减其下一年度碳排放配额。</p>

        <p>第四十一条 违反本办法规定，涉嫌构成犯罪的，有关生态环境主管部门应当依法移送司法机关。</p>
      </section>

      <section>
        <h3>第八章 附 则</h3>
        <p>第四十二条 本办法中下列用语的含义：</p>
        <p>（一）温室气体：是指大气中吸收和重新放出红外辐射的自然和人为的气态成分，包括二氧化碳（CO2）、甲烷（CH4）、氧化亚氮（N2O）、氢氟碳化物（HFCs）、全氟化碳（PFCs）、六氟化硫（SF6）和三氟化氮（NF3）。</p>
        <p>（二）碳排放：是指煤炭、石油、天然气等化石能源燃烧活动和工业生产过程以及土地利用变化与林业等活动产生的温室气体排放，也包括因使用外购的电力和热力等所导致的温室气体排放。</p>
        <p>（三）碳排放权：是指分配给重点排放单位的规定时期内的碳排放额度。</p>
        <p>（四）国家核证自愿减排量：是指对我国境内可再生能源、林业碳汇、甲烷利用等项目的温室气体减排效果进行量化核证，并在国家温室气体自愿减排交易注册登记系统中登记的温室气体减排量。</p>

        <p>第四十三条 本办法自2021年2月1日起施行。</p>
      </section>
    </main>
  </div>
</template>  
  
<script>
export default {
  // ... 组件的其他选项和数据 ...
};
</script>  
  
<style scoped>
.notification-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
}

main {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* 其他样式可以根据需要添加 */
</style>