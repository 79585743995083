<template>
  <div>
    <div class="about">
      <div class="xinwen1">
        <div class="xinwen_left">
          <img
            src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen-log.png"
            style="width: 100px;"
          />
        </div>
        <div class="xinen_right">
          <p>财政部关于印发《碳排放权交易有关会计处理暂行规定》的通知</p>
          <p style=" font-size:13px;  color:gray;">
            国务院有关部委、有关直属机构，各省、自治区、直辖市、计划单列市财政厅（局），新疆生产建设兵团财政局，财政部各地监管局，有关单位：
            为配合我国碳排放权交易的开展，规范碳排放权交易相关的会计处理，根据《中华人民共和国会计法》和企业会计准则等相关规定，我们制定了《碳排放权交易有关会计处理暂行规定》，现予印发。
          </p>
          <footer class="footer">
            <button class="but" @click="select1()">了解更多</button>
          </footer>
        </div>
      </div>
      <div class="xinwen1">
        <div class="xinwen_left">
          <img
            src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen-log.png"
            style="width: 100px;"
          />
        </div>
        <div class="xinen_right">
          <p>生态环境部办公厅关于全国碳排放权交易市场2021、2022年度碳排放配额清缴相关工作的通知</p>
          <p style=" font-size:13px;  color:gray;">
            各省、自治区、直辖市生态环境厅（局），新疆生产建设兵团生态环境局，湖北碳排放权交易中心、上海环境能源交易所：
            为做好全国碳排放权交易市场（以下简称全国碳市场）2021、2022年度碳排放配额清缴相关工作，保障全国碳市场健康平稳运行，
          </p>
          <footer class="footer">
            <button class="but" @click="select2()">了解更多</button>
          </footer>
        </div>
      </div>
      <div class="xinwen1">
        <div class="xinwen_left">
          <img
            src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen-log.png"
            style="width: 100px;"
          />
        </div>
        <div class="xinen_right">
          <p>生态环境部办公厅关于做好全国碳排放权交易市场第一个履约周期碳排放配额清缴工作的通知</p>
          <p style=" font-size:13px;  color:gray;">
            各省、自治区、直辖市生态环境厅（局），新疆生产建设兵团生态环境局：
            为做好全国碳排放权交易市场（以下简称全国碳市场）第一个履约周期重点排放单位碳排放配额（以下简称配额）清缴工作，根据《碳排放权交易管理办法（试行）》《2019-2020年全国碳排放权交易配额总量设定与分配实施方案（发电行业）》《关于加强企业温室气体排放报告管理相关工作的通知》等相关规定，请做好以下工作：
          </p>
          <footer class="footer">
            <button class="but" @click="select3()">了解更多</button>
          </footer>
        </div>
      </div>
      <div class="xinwen1">
        <div class="xinwen_left">
          <img
            src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen-log.png"
            style="width: 100px;"
          />
        </div>
        <div class="xinen_right">
          <p>
            中华人民共和国生态环境部令
            （第19号）
          </p>
          <p
            style=" font-size:13px;  color:gray;"
          >《碳排放权交易管理办法（试行）》已于2020年12月25日由生态环境部部务会议审议通过，现予公布，自2021年2月1日起施行。</p>
          <footer class="footer">
            <button class="but" @click="select4()">了解更多</button>
          </footer>
        </div>
      </div>
    </div>
    <header id="myHeader3"></header>
  </div>
</template>

<script>
export default {
  data: {
    return: {
      i: 0
    }
  },
  methods: {
    select1() {
      this.$router.push({ name: "new11" });
    },
    select2() {
      this.$router.push({ name: "new22" });
    },
    select3() {
      this.$router.push({ name: "new33" });
    },
    select4() {
      this.$router.push({ name: "new44" });
    }
  }
};
</script>
<style scoped>
.about {
  /* 设置背景图片 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new-xinwen.png");
  /* 设置背景图片不重复 */
  background-repeat: no-repeat;
  /* 设置背景图片覆盖整个容器 */
  background-size: cover;
  /* 设置背景图片的位置（可选） */
  background-position: center center;

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.xinwen1 {
  background-color: white;
  padding: 20px;
  width: 1000px;
  height: 120px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  box-shadow: 5px 5px 5px 0px rgba(204, 204, 204, 0.75);
}
.xinwen2 {
  background-color: white;
  width: 500px;
  height: 50px;
}
.but {
  margin-left: 820px;
}
#myHeader3 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 360px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new-wei.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
</style>
