<template>
    <div>
      <input type="file" @change="handleFileChange" accept=".doc,.docx" />
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      handleFileChange(e) {
        const file = e.target.files[0];
        this.uploadFile(file);
      },
      uploadFile(file) {
        let formData = new FormData();
        formData.append("file", file);
  
        // 使用axios上传文件
        axios.post('http://localhost:3000/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        })
        .then(response => {
          console.log(response);
          // 处理上传后的操作
        })
        .catch(error => console.error(error));
      },
    },
  };
  </script>
  