<template>
  <div class="notification-page">
    <header>
      <h1>生态环境部办公厅关于全国碳排放权交易市场2021、2022年度碳排放配额清缴相关工作的通知</h1>
      <p class="date">（环办气候函〔2023〕237号）</p>
    </header>

    <main>
      <p>各省、自治区、直辖市生态环境厅（局），新疆生产建设兵团生态环境局，湖北碳排放权交易中心、上海环境能源交易所：</p>
      <p>为做好全国碳排放权交易市场（以下简称全国碳市场）2021、2022年度碳排放配额清缴相关工作，保障全国碳市场健康平稳运行，根据《关于做好2021、2022年度全国碳排放权交易配额分配相关工作的通知》（以下简称《配额分配工作通知》）相关规定，有关要求通知如下。</p>

      <section>
        <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new22.jpg" width="800px" height="300px">
      </section>
      <section>
        <h4>一、差异化开展配额分配</h4>
        <p>
          对全部排放设施关停或淘汰后仍存续的重点排放单位，不予发放预分配配额，在核定阶段统一发放；对因涉法、涉诉、涉债或涉司法冻结等情况存在履约风险的重点排放单位，调整配额发放及履约方式。对以上重点排放单位，在核定阶段，其配额发放至省级生态环境主管部门账户，并由省级生态环境主管部门将履约通知书发放至重点排放单位；在清缴阶段，省级生态环境主管部门委托全国碳排放权注册登记机构（以下简称注登机构）对重点排放单位配额进行强制履约（优先使用当年度配额，剩余部分优先用于另一年度的强制履约），完成履约后剩余部分配额发放至重点排放单位账户，未足额完成履约的应及时督促重点排放单位补足差额、完成履约。对全部排放设施关停或淘汰后不再存续的重点排放单位（以营业执照注销为准），不发放配额，不参与全国碳市场履约。
          对符合上述情形的重点排放单位，不可预支2023年度配额，省级生态环境主管部门应在全国碳市场管理平台上的“配额核定同步”模块进行添加“特殊说明”操作，在重点排放单位核定配额实际发放汇总表“需特殊说明的事项”一栏中予以明确标记，建立清单。
        </p>
        <!-- 其他章节内容继续添加 -->
      </section>

      <section>
        <h4>二、组织开展国家核证自愿减排量（CCER）抵销配额清缴</h4>
        <p>组织有意愿使用CCER抵销碳排放配额清缴的重点排放单位抓紧开立账户，尽快完成CCER购买并申请抵销，抵销比例不超过对应年度应清缴配额量的5%。对第一个履约周期出于履约目的已注销但实际未用于抵销清缴的CCER，由重点排放单位申请，可用于抵销2021、2022年度配额清缴。</p>
      </section>

      <section>
        <h3>三、2023年度配额预支和个性化纾困方案申报</h3>
        <p>对承担重大民生保障任务且无法完成履约的重点排放单位，各省级生态环境主管部门应组织相关单位提出申请，结合实际情况研究制定相应的纾困方案，并于2023年8月4日前通过正式文件报送我部应对气候变化司，抄送注登机构。我部将统筹考虑纾困措施。</p>
        <p>各省级生态环境主管部门应组织满足《配额分配工作通知》要求的重点排放单位申报预支2023年度配额，研究确定预支2023年度配额的企业名单，审核确定其预支配额量，并在重点排放单位核定配额实际发放汇总表中填报。重点排放单位申报材料需上传至全国碳市场管理平台，并于2023年8月4日前通过正式文件报送注登机构，同时抄送我部应对气候变化司</p>
      </section>

      <section>
        <h3>四、配额结转</h3>
        <p>重点排放单位持有的2019—2020年度配额、2021年度配额和2022年度配额均可用于2021年度、2022年度清缴履约，也可用于交易。</p>
        <!-- ... 其他使用配额的账务处理细节 ... -->

        <!-- ... 继续添加其他账务处理细节，如出售、注销等 ... -->
      </section>

      <section>
        <h4>五、保障措施</h4>
        <ol>
          <li>
            <strong>（一）加强组织领导。</strong>
            各省级生态环境主管部门应统筹做好配额交易及履约清缴相关协调工作，建立工作调度机制，加强培训...（省略具体内容）
          </li>
          <li>
            <strong>（二）加强履约监管。</strong>
            注登机构会同各省级生态环境主管部门研究建立履约风险动态监管机制...（省略具体内容）
          </li>
          <li>
            <strong>（三）完善履约机制。</strong>
            对履约截止日期后仍未足额清缴配额的重点排放单位...（省略具体内容）
          </li>
        </ol>

        <h3>报送截止时间变更通知</h3>
        <p>2021、2022年度机组核定配额明细表和重点排放单位核定配额实际发放汇总表报送截止时间由2023年7月15日延至8月4日，注登机构应于8月11日前完成配额和履约通知书发放工作。</p>

        <div class="contact-info">
          <h3>联系人：</h3>
          <ul>
            <li>
              生态环境部应对气候变化司 邓朝阳、邹毅
              电话：（010）65645635、65645641
            </li>
            <!-- 其他联系人信息... -->
          </ul>
        </div>

        <p>
          <strong>附件：</strong> 2021、2022年度全国碳市场重点排放单位使用CCER抵销配额清缴程序
        </p>

        <p>生态环境部办公厅</p>
        <p>2023年7月14日</p>
      </section>
    </main>

    <footer>
      <p>&copy; 财政部 2019</p>
    </footer>
  </div>
</template>  
  
<script>
export default {
  // ... 组件的其他选项和数据 ...
};
</script>  
  
<style scoped>
.notification-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
}

main {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* 其他样式可以根据需要添加 */
</style>