<template>
  <div>
    <component :is="selectedComponent" />
  </div>
</template>

<script>
import MaiMai from "@/use-components/maimai.vue";
import RongZi from "@/use-components/rongzi.vue";
import KaiFa from "@/use-components/kaifa.vue";


// 假设你有一个组件映射
const componentMap = {
  1: MaiMai, // 当id为1时，使用MaiMai组件
  2: RongZi ,
  3: KaiFa
};

export default {
  name: "DoctorDetail",
  // ... 其他选项，如computed属性、methods等 ...
  components: {
    MaiMai,
    RongZi,
    KaiFa
  },
  data() {
    return {
      selectedComponent: null // 用于存储要渲染的组件
    };
  },
  created() {
    // 使用this.$route.params.id来获取医生的id，并据此加载医生的详细信息
    const doctorId = this.$route.params.id;

    // 根据id从组件映射中获取对应的组件
    this.selectedComponent = componentMap[doctorId] || null;
  }
};
</script>

<style>
</style>