<template>
  <div class="app-container">
    <div class="sidebar">
      <div
        v-for="item in menuItems"
        :key="item.id"
        class="menu-item"
        @click="selectItem(item)"
        @mouseover="hoverItem(item)"
        @mouseleave="unhoverItem(item)"
        :class="{ active: item.active, hover: item.hover }"
      >
        <router-link :to="{ name: item.name.replace(' ', '') }"></router-link>
        {{ item.name }}
      </div>
    </div>
    <div class="content">
      <input type="text" placeholder="Search here..." class="search-box" />
      <div class="cards-container">
        <div
          v-for="doctor in doctors"
          :key="doctor.id"
          class="card"
          @click="selectDoctor(doctor)"
          @mouseover="hoverItem1(doctor)"
          @mouseleave="unhoverItem1(doctor)"
          :class="{ 'card': true, hover: doctor.hover, 'selected': doctor.selected }"
        >
          <div class="card-image" :style="{ backgroundImage: 'url(' + doctor.imageUrl + ')' }"></div>
          <!-- Placeholder for doctor's image -->
          <div class="card-info">
            <h3>{{ doctor.name }}</h3>
            <p :style="{ 'font-size':'14px', 'color': '#888888' }">{{ doctor.specialty }}</p>
            <p :style="{'font-size':'17px' }">
              {{ doctor.jianjie }}
              <br />
              <br />
            </p>
            <p
              :style="{ whiteSpace: 'pre-wrap','font-size':'14px', 'color': '#888888' }"
            >{{ doctor.beizhu }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出的页面内容 -->
    <el-dialog :visible.sync="getmessage" title="报告下载" width="45%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
      >
     
        <!-- 企业基本信息展示 -->
        <el-form-item label="">
          <el-card class="info-card">
            <!-- 企业信息尽调报告 -->
            <div class="report-item">
              <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/qiyexinxi.jpg" alt="地球图标" />
              <!-- 这里使用你的图片资源 -->
              <div class="text">
              <h3>企业信息尽调报告</h3>
              <p style="font-size: 12px; color:gray ; line-height: 15px;">全面展现企业基本信息，工商、涉诉、风险、知识产权等信息，支持您的尽调工作</p>
              <button class="download-btn" @click="select()" >下载报告</button>
            </div>
            </div>

            <!-- 企业风险分析报告 -->
            <div class="report-item">
              <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/qiyefengxian.jpg" alt="盾牌图标" />
              <!-- 这里使用你的图片资源 -->
              <div class="text">
              <h3>企业风险分析报告</h3>
              <p style="font-size: 12px; color:gray ; line-height: 15px;">专项集成企业风险相关信息，深入分析企业各项风险，助力提升客户服务质量</p>
              <button class="download-btn" @click="select()">下载报告</button>
              </div>
            </div>

            <!-- 企业商标分析报告 -->
            <div class="report-item">
              <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/qiyeshangbiao.jpg" alt="植物图标" />
              <!-- 这里使用你的图片资源 -->
              <div class="text">
              <h3>企业商标分析报告</h3>
              <p style="font-size: 12px; color:gray ; line-height: 15px;">全面分析企业商标申请、涉诉、确权等信息，为客户提供更专业的知识产权服务</p>
              <button class="download-btn" @click="select() ">下载报告</button>
              </div>
            </div>
          </el-card>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      menuItems: [
        { id: 1, name: "Dashboard", active: false, hover: false },
        { id: 2, name: "Appointment", active: false, hover: false },
        { id: 3, name: "合同类型", active: true, hover: true },
        { id: 4, name: "合同审查", active: false, hover: false },
        { id: 5, name: "法律指导报告", active: false, hover: false },
        { id: 6, name: "Chats", active: false, hover: false },
        { id: 7, name: "Calls", active: false, hover: false },
        { id: 8, name: "Register Patients", active: false, hover: false },
        { id: 9, name: "Settings", active: false, hover: false }
      ],
      getmessage: false, // 控制弹窗显示与隐藏的状态
      doctors: [
        {
          id: 1,
          name: "买卖合同",
          specialty: " MBBS, M.D of Medicine",
          jianjie: "碳配额交易合同模版",
          beizhu: "CCER（核证自愿减排量交易）\n买卖合同模版",
          imageUrl:
            "https://img.js.design/assets/img/629d91b131975cce8add0da5.jpg",
          selected: false,
          hover: false
        },
        {
          id: 2,
          name: "融资类合同",
          specialty: "MBBS, M.D of Medicine",
          jianjie: "抵质押融资合同模版",
          imageUrl:
            "https://img.js.design/assets/img/629d91b79c8c86611c8c4286.jpg",
          selected: false,
          hover: false
        },
        {
          id: 3,
          name: "开发合同",
          specialty: "MBBS, M.D of Medicine",
          jianjie: "碳汇开发合同模版",
          beizhu: "Phone: + (123) 1800-567\nEmail: jacad@gmail.com",
          imageUrl:
            "https://img.js.design/assets/img/629d91b6faded55d42374dfb.jpg",
          selected: false,
          hover: false
        },
        {
          id: 4,
          name: "担保合同",
          specialty: "MBBS, M.D of Medicine",
          jianjie: "Psychaiatrist",
          beizhu: "Phone: + (123) 1800-567\nEmail: jacad@gmail.com",
          imageUrl:
            "https://img.js.design/assets/img/629d91b6faded55d42374dfb.jpg",
          selected: false,
          hover: false
        },
        {
          id: 5,
          name: "中介合同",
          specialty: "MBBS, M.D of Medicine",
          jianjie: "Neurologist",
          beizhu: "Phone: + (123) 1800-567\nEmail: jacad@gmail.com",
          imageUrl:
            "https://img.js.design/assets/img/629d91b7e2ae2834c236a2fe.jpg",
          selected: false,
          hover: false
        },
        {
          id: 6,
          name: "其他合同",
          specialty: "MBBS, M.D of Medicine",
          jianjie: "碳中和项目服务合同模版",
          beizhu: "Phone: + (123) 1800-567\nEmail: jacad@gmail.com",
          imageUrl:
            "https://img.js.design/assets/img/629d91b3823cb075a41cf81c.jpg",
          selected: false,
          hover: false
        }
        // Add more doctors as needed
      ]
    };
  },
  methods: {
    select(){
alert('下载成功')
    },
    selectItem(item) {
      this.menuItems.forEach(i => {
        i.active = false;
      });
      item.active = true;
      if (item.id === 5) {
        this.getmessage = true; // 点击菜单项id为5时显示弹窗
      }
      if (item.name === "合同审查") {
        this.$router.push({ name: "shenchademo" });
      }
    },
    hoverItem(item) {
      item.hover = true;
    },
    unhoverItem(item) {
      item.hover = false;
    },
    hoverItem1(doctor) {
      doctor.hover = true;
    },
    unhoverItem1(doctor) {
      doctor.hover = false;
    },
    selectDoctor(doctor) {
      console.log("Doctor selected:", doctor.name);
      this.$router.push({ name: "doctor-detail", params: { id: doctor.id } });
      doctor.selected = !doctor.selected;
    }
  }
};
</script>
  
  <style scoped>
.app-container {
  display: flex;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
  height: 100vh;
}

.menu-item {
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.menu-item.hover {
  background-color: #e0e0e0;
}

.menu-item.active {
  background-color: #3b6bee;
  color: #e0e0e0;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.search-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  cursor: pointer;
}
.card.hover {
  /* transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2); */
  border: 1px solid #007bff; /* Blue border for selected card */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.card.selected {
  border: 1px solid #007bff; /* Blue border for selected card */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.card-image {
  width: 65px; /* 设置您想要的宽度 */
  height: 63px; /* 设置您想要的高度 */
  background-color: #eee; /* 可选，用于图片加载失败时的背景色 */
  margin-right: 20px; /* 根据需要设置外边距 */
  border-radius: 25px;

  background-size: cover; /* 使图片覆盖整个容器，根据需要调整 */
  background-position: center center; /* 图片居中显示，根据需要调整 */
  /* 其他样式属性... */
}
.info-card {
  display: flex;
 
  width: 400px;
  justify-content: flex-start; /* 项目在主轴（水平方向）上的对齐方式 */  
  align-items: stretch; /* 项目在交叉轴（垂直方向）上如何对齐 */  
  gap: 30px; /* 项目之间的间隔 */  
  margin-bottom: 20px;
}

.report-item {
  display: flex;
  flex: 1 0 calc(33.333% - 20px); /* flex-grow, flex-shrink, flex-basis */  
  margin-bottom: 20px; /* 为每个报告项目添加底部外边距 */  
  align-items: center; /* 垂直居中对齐 */  
  text-align: center;
  width:300px; /* 根据需要调整 */
  height: 180px;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.report-item img {
  width: 50px; /* 图标大小 */
  height: 70px;
  margin-bottom: 10px;
}

.report-item p {
  margin: 0;
  font-size: 14px;
}


.report-item .download-btn {
  position: absolute;
  bottom:15px;
  left: 75%;
  transform: translateX(-50%);
  background-color: #fbc862; /* 按钮颜色 */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.download-btn:hover {  
  /* 鼠标悬停时的样式 */  
  background-color: #f38434; 
  
  
}
.coming-soon {
  background-color: #ddd; /* 即将上线项的背景颜色 */
  color: #888;
}
</style>
  