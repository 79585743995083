<template>
  <div>
    <input type="file" @change="handleFileChange" accept=".doc, .docx" style="height: 42px "/>
    <div class="overall">
      <div class="overall_left">
        <h1>碳排放权交易买卖合同</h1>
        <div class="contract-section">
          <p>甲方（买方）：ABC公司</p>
          <p>法定代表人：小夏</p>
          <p>电话：12379224611</p>
          <p>住所：A市B区xx街道</p>
        </div>

        <div class="contract-section">
          <p>乙方（卖方）：常口村（村集体）</p>
          <p>法定代表人：小红</p>
          <p>电话：18202635783</p>
          <p>住所：常口村xx号</p>
        </div>

        <div class="contract-section" :class="{ 'is-active': activeFruit === 1 }" data-id="1">
         
          <p>丙方（交易所）：福建ABC联合产权交易所集团股份有限公司</p>
          <p>法定代表人：李二狗</p>
        </div>

        <div class="contract-section">
          <p>
            根据 《中华人民共和国民法典合同典》、
            《福建省碳排放权交易管理暂行办法》和《
            福建联合产权交易所碳排放交易细则（试行）》 等法律、
            规范性文件和规则的规定，本着自愿、诚实信用的原则，
            三方就乙方购买甲方持有的常口村碳排放配额达成以下条款：
          </p>
        </div>
        <div class="contract-section">
          <p>
            <strong>一.买卖标的的名称、单位、数量</strong>
          </p>
          <p>名称：常口村碳排放配额（以下简称"配额"）</p>
          <p>单位：吨二氧化碳当量(tCO2e)</p>
          <p>数量：10 tCO2e</p>
        </div>

        <div class="contract-section" :class="{ 'is-active': activeFruit === 2 }" data-id="2">
          <p>
            <strong>二.交易单价及交易价款</strong>
          </p>
          <p>交易单价：人民币 元/tCO2e</p>
          <p>交易价款：人民币 元整</p>
        </div>

        <div class="contract-section">
          <p>
            <strong>三.交收程序</strong>
          </p>
          <p>详细描述了甲乙方及丙方的交收步骤</p>
        </div>

        <div class="contract-section" :class="{ 'is-active': activeFruit === 3 }" data-id="3">
          <p>
            <strong>四.交易税费</strong>
          </p>
          <p>丙方按合同成交金额7%标准，分别向甲乙双方收取交易手续费</p>
          <p>乙方应当承担主要的税费</p>
        </div>

        <div class="contract-section">
          <p>
            <strong>五.责任义务</strong>
          </p>
          <p>规定了甲乙丙三方的责任和义务</p>
        </div>

        <div class="contract-section" :class="{ 'is-active': activeFruit === 4 }" data-id="4">
          <p>
            <strong>六.违约责任</strong>
          </p>
          <p>若有一方违反本合同的约定，即视为违约</p>
        </div>

        <div class="contract-section">
          <p>
            <strong>七.争议解决</strong>
          </p>
          <p>协商不成时，优先考虑仲裁，仲裁不成则诉讼解决</p>
        </div>

        <div class="contract-section">
          <p>
            <strong>第八条 其他事项</strong>
          </p>
          <p>本合同自双方签字（或盖章）之日起生效。本合同一式两份，甲乙双方各执一份。本合同未尽事宜，可由双方协商补充。经双方协商一致达成的补充协议，与本合同具有同等法律效力。</p>

          <p>
            甲方（盖章）：
            <br />法定代表人（签字）：
            <br />签订日期：____年__月__日
          </p>

          <p>
            乙方（盖章）：
            <br />法定代表人（签字）：
            <br />签订日期：____年__月__日
          </p>
          <p>
            丙方（盖章）：
            <br />法定代表人（签字）：
            <br />签订日期：____年__月__日
          </p>
        </div>
      </div>

      <div class="overall_right">
        <p class="underlined-text">审查结果</p>
        <div class="over_right_head">
          <div class="over_right_head_1">
            <p style="margin-left: 22px; ">风险等级</p>
          </div>
          <div class="over_right_head_2">
            <button
              class="filter-btn"
              v-for="(filter, index) in filters"
              :key="index"
              @click="applyFilter(filter.value)"
              :class="{ active: currentFilter === filter.value }"
            >{{ filter.text }}</button>
          </div>

          <div class="fruit-list">
            <ul>
              <li
                v-for="fruit in filteredFruits"
                :key="fruit.id"
                @click="setActiveFruit(fruit.id)"
                :class="{ active: fruit.id === activeFruit }"
              >{{ fruit.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>  
  <script>
export default {
  data() {
    return {
      filters: [
        { text: "显示全部", value: "all" },
        { text: "重大风险", value: "banana" },
        { text: "一般风险", value: "apple" }
      ],
      fruits: [
        { id: 1, name: "> 交易主体不适格及信息缺失" },
        { id: 2, name: "> 未标注交易标的" },
        { id: 3, name: "> 税费需双方共同承担 "},
        { id: 4, name: " > 一方责任过重" },
      ],
      currentFilter: "all", // 初始筛选条件
      activeFruit: 0
    };
  },
  computed: {
    filteredFruits() {
      if (this.currentFilter === "all") {
        // 如果筛选条件是 "all"，则返回所有水果
        return this.fruits;
      } else {
        // 否则，返回与筛选条件完全匹配的水果
        return this.fruits.filter(fruit => fruit.name === this.currentFilter);
      }
    }
  },
  methods: {
    applyFilter(filterValue) {
      this.currentFilter = filterValue;
    },
    setActiveFruit(fruitId) {
      this.activeFruit = fruitId; // 更新被点击的水果
    },
    handleFileChange(e) {
    const file = e.target.files[0];
    this.uploadFile(file);
  },
  uploadFile(file) {
    let formData = new FormData();
    formData.append("file", file);

    // 使用axios上传文件
    axios
      .post("http://localhost:3000/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        console.log(response);
        // 处理上传后的操作
      })
      .catch(error => console.error(error));
  }
  },
 
};
</script>
  <style scoped>
.overall {
  width: 100%;

  margin: auto;
  background-color: #eff0f2;
  display: flex;
}
.overall_left {
  width: 65%;
  height: 80%;
  background-color: white;
  font-family: Arial, sans-serif;
  margin: 30px;
  margin-right: -10px;
  padding: 20px;
}
.overall_right {
  width: 30%;
  height: 100%;
  /*background-color: white;*/
  margin: 30px;
  margin-right: 100px;
  background-color: white;
  border-radius: 10px;
  padding-left: -10px;
}
h1 {
  text-align: center;
}
.contract-section {
  margin-bottom: 20px;
}
.contract-section h2 {
  font-size: 18px;
  margin-bottom: 10px;
}
.contract-section p {
  margin-bottom: 5px;
}

/*右边 */

.underlined-text {
  display: inline-block; /* 使得伪元素可以相对于这个元素定位 */
  position: relative;
  margin: 10px;
  padding: 15px;
}

.underlined-text::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px; /* 调整这个值来改变下滑线和文字之间的距离 */
  width: 100%;
  height: 2px;
  background-color: rgb(84, 139, 250); /* 自定义下滑线颜色为天蓝色 */
}

/**风险 */
.over_right_head {
  background-color: white;
  border-radius: 10px;
}
.over_right_head_2 {
  width: 100%;
  height: 50%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.filter-btn {
  /* 定义按钮基本样式 */
  display: inline-block; /* 如果需要水平排列，则更改为 inline-block 或 flex 子项 */
  margin-bottom: 5px; /* 根据需要调整 */
  padding: 10px 20px;
  margin: 5px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  cursor: pointer; /* 添加鼠标悬停时的效果 */
  transition: background-color 0.3s ease; /* 添加过渡效果 */
}

.filter-btn:hover {
  /* 鼠标悬停时的样式 */
  background-color: #85a4f8;
  color: white;
}

.filter-btn.active {
  /* 当前激活的筛选按钮样式 */
  background-color: #85a4f8;
  color: white;
}
/* 如果需要水平排列按钮，可以添加以下样式 */
.over_right_head_2.horizontal {
  flex-direction: row;
  justify-content: space-between; /* 或者使用其他值如 center 来调整按钮间距 */
}

.over_right_head_2.horizontal .filter-btn {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 5px; /* 根据需要调整 */
}
.fruit-list {
  /* 水果列表的样式 */
  list-style-type: none;
  padding: 0;
  margin: 30px;
  border: solid 1px gainsboro;
  width: 80%; /* 根据需要设置列表宽度 */
}

.fruit-list li {
  /* 列表项的样式 */
  list-style-type: none;

  padding: 10px;
  border: 1px solid #ddd; /* 添加分隔线 */
  margin-bottom: 20px; /* 添加列表项之间的垂直间距 */
}

.fruit-list li:last-child {
  /* 最后一个列表项不添加分隔线 */
  border-bottom: none;
}

.fruit-list li:hover {
  /* 鼠标悬停时的样式 */
  background-color: #f5f5f5; /* 浅灰色背景 */
  cursor: pointer; /* 可选，如果列表项可点击 */
}

 
.fruit-list li.is-active {
  /* 可选：当前被点击的列表项样式 */
  font-weight: bold;
}
.fruit-list li.active {
  /* 当前激活的筛选按钮样式 */
   border: solid rgb(131, 131, 236) 1px;
}
.is-active {
  /* 当activeFruit被设置时，标红 */
  color: red; /* 文字颜色变为红色 */
}

</style>