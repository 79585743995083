<template>
  <div class="notification-page">
    <header>
      <h1>生态环境部办公厅关于做好全国碳排放权交易市场第一个履约周期碳排放配额清缴工作的通知</h1>
      <p class="doc-number">（环办气候函〔2021〕492号）</p>
    </header>

    <main>
      <h3>各省、自治区、直辖市生态环境厅（局），新疆生产建设兵团生态环境局：</h3>
      <p>为做好全国碳排放权交易市场（以下简称全国碳市场）第一个履约周期重点排放单位碳排放配额（以下简称配额）清缴工作，根据《碳排放权交易管理办法（试行）》《2019-2020年全国碳排放权交易配额总量设定与分配实施方案（发电行业）》《关于加强企业温室气体排放报告管理相关工作的通知》等相关规定，请做好以下工作：</p>

      <section>
        <img
          src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new33.jpg"
          width="800px"
          height="300px"
        />
      </section>
      <section>
        <p>一、抓紧完成本行政区域发电行业重点排放单位2019-2020年度配额核定及清缴配额量确认，报送我部并抄送全国碳排放权注册登记机构（湖北碳排放权交易中心），在全国排污许可证管理信息平台碳排放数据报送功能模块完成配额核定、应清缴配额量有关信息填报，通过全国碳排放权注册登记系统向重点排放单位分配经核定配额。按照我部《关于做好全国碳排放权交易市场数据质量监督管理相关工作的通知》要求，高度重视并认真做好全国碳市场数据质量监督管理工作，发现数据虚报、瞒报、数据造假等违规行为严格按照相关规定进行处罚并组织整改，对排放数据存在造假情况的重点排放单位，依据相关技术规范按保守性原则审慎确定其排放量和应清缴配额，有关处理情况报送我部。</p>
        <!-- 其他章节内容继续添加 -->
      </section>

      <section>
        <p>二、督促发电行业重点排放单位尽早完成全国碳市场第一个履约周期配额清缴，确保2021年12月15日17点前本行政区域95%的重点排放单位完成履约，12月31日17点前全部重点排放单位完成履约。碳排放配额清缴范围为已纳入2019-2020年全国碳排放权交易预分配配额管理的重点排放单位。对未按时足额清缴配额的重点排放单位，依据《碳排放权交易管理办法（试行）》相关规定处理，有关处理情况于2022年1月15日前报送我部。我部将对上述工作进行核实，并对落实不力的进行督办。</p>
      </section>

      <section>
        <p>三、组织有意愿使用国家核证自愿减排量（CCER）抵销碳排放配额清缴的重点排放单位抓紧开立国家自愿减排注册登记系统一般持有账户，并在经备案的温室气体自愿减排交易机构开立交易系统账户，尽快完成CCER购买并申请CCER注销。使用CCER抵销碳排放配额清缴的相关具体程序和申请表附后。</p>
      </section>

      <section>
        <p>四、加强对本行政区域重点排放单位配额清缴工作的组织领导。重点督促指导配额存在缺口的重点排放单位尽早做好清缴相关工作，与全国碳排放权注册登记机构（湖北碳排放权交易中心）和全国碳排放权交易机构（上海环境能源交易所）加强对接。</p>
      </section>

      <section>
        <h2>全国碳排放权交易与抵销配额清缴联系信息</h2>
        <p>为了确保全国碳排放权交易顺利进行，及时解决工作中遇到的问题，我们提供了以下联系信息和相关单位的支持服务：</p>
        <p>
          <strong>湖北碳排放权交易中心（全国碳排放权注册登记机构）</strong>
          <br />联系人：徐泽夏
          <br />电话：4009911188转0
        </p>
        <p>
          <strong>上海环境能源交易所（全国碳排放权交易机构）</strong>
          <br />联系人：臧奥乾
          <br />电话：（021）56903000转全国碳市场运营中心
        </p>
        <p>
          <strong>北京绿色交易所（自愿减排注册登记系统技术支撑）</strong>
          <br />联系人：高原、刘晓嫣
          <br />电话：（010）66295772、66295589
        </p>
        <p>
          <strong>环境工程评估中心（全国排污许可证管理信息平台碳排放数据报送功能模块技术支持）</strong>
          <br />联系人：齐硕
          <br />电话：（010）84757220
        </p>
        <p>
          <strong>国家气候战略中心</strong>
          <br />联系人：刘海燕
          <br />电话：（010）82268464
        </p>
        <p>
          <strong>生态环境部应对气候变化司</strong>
          <br />联系人：齐磊
          <br />电话：（010）65645638
        </p>
        <p>
          <strong>附件：</strong>
          <br />1. 全国碳市场第一个履约周期使用CCER抵销配额清缴程序
          <br />2. 全国碳市场第一个履约周期重点排放单位使用CCER抵销配额清缴申请表
        </p>
        <p>
          <strong>重要通知：</strong>
          <br />重点排放企业的国家核证自愿减排量（CCER）相关交易，参照以下规定进行会计处理，在“碳排放权资产”科目下设置明细科目进行核算。
        </p>
        <section>
          <p>重点排放企业的国家核证自愿减排量（CCER）相关交易，参照本规定进行会计处理，在“碳排放权资产”科目下设置明细科目进行核算。</p>
          <p>本规定自2020年1月1日起施行，重点排放企业应当采用未来适用法应用本规定。</p>
        </section>
      </section>
    </main>

    <footer>
      <p>&copy; 财政部 2019</p>
    </footer>
  </div>
</template>  
  
<script>
export default {
  // ... 组件的其他选项和数据 ...
};
</script>  
  
<style scoped>
.notification-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
}

main {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* 其他样式可以根据需要添加 */
</style>