<template>
  <div class="about">
    <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/new-hangye.png" style="height: 1300px; width:1500px" />
  </div>
</template>

<script>
export default {
  
}
</script>
<style scoped>

</style>
