<template>
  <div class="qudao">
    <QuDaoHe></QuDaoHe>
  
  </div>
</template>
  
  <script>
  import QuDaoHe from "@/components/qudaohezuo.vue";

export default {
  name: "qudao",
  components: {
    QuDaoHe,
  }
};
</script>
  <style scoped>
* {
  margin: 0;
  padding: 0;
}

</style>