<template>
    <div class="about">
      <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/we-yuanqu.png" style="height: 3300px; width:1500px" />
    </div>
  </template>

  <script>
  export default {
    
  }
  </script>
  <style scoped>
  
  </style>
  