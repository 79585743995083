<template>
  <div class="outer-container">
    <div class="ccer-contract">
      <h2>回购融资合同</h2>
      <div class="contract-content">
      <p>合同编号：____</p>

      <h3>甲方（融资方）：_____</h3>
      <h3>乙方（投资方）：_____</h3>

      <p>根据《中华人民共和国合同法》、《中华人民共和国公司法》等有关法律法规，甲乙双方在平等、自愿、公平、诚实信用的基础上，就甲方进行的回购融资活动达成如下协议：</p>

      <h3>第一条 定义</h3>
      <p>1. “回购融资”是指甲方通过向乙方出售其持有的特定资产或股权，并在约定期限内以事先约定的价格回购该资产或股权的一种融资方式。</p>
      <p>2. “回购期限”是指甲方与乙方约定的，自本次融资完成之日起至甲方回购该资产或股权之日止的期限。</p>

      <h3>第二条 融资内容及金额</h3>
      <p>1. 甲方将其持有的____（资产/股权）出售给乙方，作为本次融资的标的。</p>
      <p>2. 乙方同意向甲方支付融资款人民币__元，用于甲方进行__（具体用途）。</p>

      <h3>第三条 回购条款</h3>
      <p>1. 甲方应在回购期限结束前，按照事先约定的价格回购本次融资的标的。</p>
      <p>2. 如甲方未能按时回购，应向乙方支付违约金，违约金金额为未回购部分金额的__%。</p>

      <h3>第四条 风险控制</h3>
      <p>1. 甲方应保证所出售的资产或股权不存在任何权利瑕疵或限制，否则应承担由此产生的一切法律责任。</p>
      <p>2. 乙方有权对甲方的财务状况、经营情况进行监督，确保融资款项的合理使用。</p>

      <h3>第五条 违约责任</h3>
      <p>1. 如甲方未按照约定用途使用融资款项，应向乙方支付违约金，并赔偿因此给乙方造成的损失。</p>
      <p>2. 如乙方未按照约定支付融资款项，应向甲方支付违约金，并赔偿因此给甲方造成的损失。</p>

      <h3>第六条 争议解决</h3>
      <p>因本合同引起的或与本合同有关的任何争议，双方应首先尝试友好协商解决。协商不成的，任何一方均有权向__（合同签订地/被告住所地）人民法院提起诉讼。</p>

      <h3>第七条 其他</h3>
      <p>1. 本合同自双方签字（或盖章）之日起生效。</p>
      <p>2. 本合同一式两份，甲乙双方各执一份。</p>
      <p>3. 本合同未尽事宜，可由双方协商补充。经双方协商一致达成的补充协议，与本合同具有同等法律效力。</p>

      <div class="contract-signature">
        <p>甲方（盖章）：</p>
        <p>法定代表人（签字）：</p>
        <p>签订日期：__年__月__日</p>

        <p>乙方（盖章）：</p>
        <p>法定代表人（签字）：</p>
        <p>签订日期：__年__月__日</p>
      </div>
      </div>
      <div class="download-section">
        <a href="URL_TO_YOUR_WORD_FILE" download="CCER交易买卖合同.docx" class="download-button">下载合同模板</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.outer-container {
  background-color: rgb(225, 225, 225);
  padding: 130px;
}
.ccer-contract {
  background-color: white; /* 显式设置为白色，但通常默认就是白色 */
  /* 其他样式，如宽度、高度等 */
  width: 100%;
  height: 100%; /* 如果需要的话 */
  box-sizing: border-box; /* 确保padding不会影响宽度 */
}
h1,
h2 {
  text-align: center;
}
p {
  margin-bottom: 1em;
}
.contract-content {
  margin: 0 auto;
  width: 80%;
}
.contract-content p {
  text-indent: 2em; /* 首行缩进 */
}
.download-section {
  text-align: center; /* 让下载按钮居中显示 */
  margin-top: 20px; /* 在下载部分和其它内容之间增加一些间距 */
}

.download-button {
  display: inline-block;
  background-color: #007bff; /* 蓝色背景 */
  color: white; /* 白色文字 */
  padding: 10px 20px; /* 内边距 */
  text-decoration: none; /* 去除下划线 */
  border-radius: 5px; /* 轻微的圆角 */
  font-size: 16px; /* 文字大小 */
  cursor: pointer; /* 鼠标悬停时显示手形图标 */
  transition: background-color 0.3s; /* 为背景色变化添加过渡效果 */
}

.download-button:hover {
  background-color: #0056b3; /* 鼠标悬停时深蓝色背景 */
}
</style>